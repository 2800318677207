import React from "react";
import { Paper } from "@mui/material";
import MyContainer from "../MyContainer";

const ChatBox = () => {
  return (
    <MyContainer>
      <Paper border={0} elevation={24}>
        <iframe
          title="chatbot"
          src="https://interfaces.zapier.com/embed/chatbot/cm2v7juot000b6ezp1jvazypi"
          allow="clipboard-write *"
          className="zapier-container"
        ></iframe>
      </Paper>
    </MyContainer>
  );
};

export default ChatBox;
