import React from "react";
import MyContainer from "../MyContainer";
import { Typography } from "@mui/material";
const Benefits = () => {
  return (
     <MyContainer>
       <section className="benefits">
         <Typography textAlign={"center"} fontWeight={"bold"} fontSize={25}>
           Beneficios que puedes encontrar
         </Typography>
         <div className="benefits-grid">
           <div className="benefit-item">
             
             <h3>Personalización avanzada:</h3>
             
             <p>
               Mediante nuestro algoritmo de emparejamiento, encuentra el
               terapeuta que mejor se adapta a ti!
             </p>
           </div>
           <div className="benefit-item">
             <h3>Sesiones transcritas:</h3>
             <p>
               Guardamos un registro de tus sesiones para un análisis preciso
               y detallado.
             </p>
           </div>
           <div className="benefit-item">
             <h3>Acceso 24/7:</h3>
             <p>
               Con nuestro Chatbot siempre disponible, te brindamos apoyo
               emocional las 24 horas del día.
             </p>
           </div>
           <div className="benefit-item">
             <h3>Fácil de usar:</h3>
             <p>
               Todo el proceso de reservar y encontrar una sesión es rápido y
               simple desde nuestra app.
             </p>
           </div>
         </div>
       </section>
    </MyContainer>
  );
};

export default Benefits;
