import React from "react";
import { Grid2 } from "@mui/material";
import PropTypes from "prop-types";

const MyContainer = (props) => {
  return (
    <Grid2
      container
      paddingTop={4}
      justifyContent={"center"}
    >
      {props.children}
    </Grid2>
  );
};

MyContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MyContainer;
