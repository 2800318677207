import React from 'react';
import { Typography, TextField, Button, Box } from '@mui/material';

const Feedback = () => (
  <Box>
        <Typography variant="h4">Bienvenido al feedback</Typography>
        <Typography>
          **placeholder de feedback**
        </Typography>
      </Box>
);

export default Feedback;
