import React, { useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import ChatBar from "./ChatBar"; // Import the ChatBar component

const ChatbotText = ({ user = "user@notdefined.cl", initialMessage = "¡Hola! ¿Cómo te sientes hoy? ¿Hay algo en particular de lo que quieras hablar?" }) => {
  const [messages, setMessages] = useState([]); // Start with an empty array
  const [input, setInput] = useState("");
  const [isFirstPress, setIsFirstPress] = useState(true); // Track if it's the first press
  const [centerMessage, setCenterMessage] = useState(initialMessage); // State for the center message

  // Function to send a new message
  const send_text = async () => {
    if (input.trim() === "") return;

    // Handle the first button press
    if (isFirstPress) {
      const welcomeMessage = {
        type: "IA",
        text: centerMessage, // Use the current center message
      };
      const userMessage = { type: "User", text: input.trim() };

      // Add both messages to the chat
      setMessages((prevMessages) => [...prevMessages, welcomeMessage, userMessage]);

      // Reset the state to indicate that the first press has been handled
      setIsFirstPress(false);

      // Send only the user's input to the server
      try {
        const response = await fetch("http://localhost:8080/api/chat-bot/query", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: user,
            message: input.trim(),
          }),
        });

        if (response.ok) {
          const data = await response.json(); // Parse the response as JSON
          const botResponse = data.response; // Extract the `response` field

          // Update the center message with the new bot response
          setCenterMessage(botResponse);

          // Add the bot response to the chat
          const newMessage = { type: "IA", text: botResponse };
          setMessages((prevMessages) => [...prevMessages, newMessage]);
        } else {
          console.error("Error sending message to the server");
        }
      } catch (error) {
        console.error("Error:", error);
      }

      setInput(""); // Clear the input
      return;
    }

    // Handle subsequent button presses (normal behavior)
    const userMessage = { type: "User", text: input.trim() };
    setMessages((prevMessages) => [...prevMessages, userMessage]);

    try {
      const response = await fetch("http://localhost:8080/api/chat-bot/query", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: user,
          message: input.trim(),
        }),
      });

      if (response.ok) {
        const data = await response.json(); // Parse the response as JSON
        const botResponse = data.response; // Extract the `response` field

        // Update the center message with the new bot response
        setCenterMessage(botResponse);

        // Add the bot response to the chat
        const newMessage = { type: "IA", text: botResponse };
        setMessages((prevMessages) => [...prevMessages, newMessage]);
      } else {
        console.error("Error sending message to the server");
      }
    } catch (error) {
      console.error("Error:", error);
    }

    setInput(""); // Clear the input
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      minHeight="100vh"
      bgcolor="#f5f5f5"
    >
      {/* Main Chat Content */}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="flex-start"
        width="75%" // Leave space for the sidebar
        padding="16px"
        sx={{ paddingTop: "10vh" }}
      >
        {/* Circular Cropped Image with Zoom */}
        <Box
          sx={{
            width: "200px",
            height: "200px",
            overflow: "hidden",
            borderRadius: "50%",
            position: "relative",
            marginBottom: "32px",
          }}
        >
          <img
            src="/ia_futuristic_teraphy.jpg"
            alt="IA Therapy Interface"
            style={{
              position: "absolute",
              top: "-885px",
              left: "-850px",
              width: "auto",
              height: "auto",
              transform: "scale(0.22)",
              transformOrigin: "center center",
            }}
          />
        </Box>

        {/* Centered Text */}
        <Typography
          sx={{
            textAlign: "center",
            maxWidth: "600px",
            marginBottom: "16px",
          }}
        >
          {centerMessage}
        </Typography>

        {/* Text Input */}
        <TextField
          variant="outlined"
          placeholder="Escribe aquí..."
          fullWidth
          value={input}
          onChange={(e) => setInput(e.target.value)}
          sx={{ maxWidth: 400, marginBottom: 2 }}
        />

        {/* Send Button */}
        <Button
          variant="contained"
          color="primary"
          onClick={send_text}
          sx={{ maxWidth: 200 }}
        >
          Enviar
        </Button>
      </Box>

      {/* Sidebar with Chat History */}
      <ChatBar messages={messages} />
    </Box>
  );
};

export default ChatbotText;
