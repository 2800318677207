import { React , useState } from 'react';
import { Box, Grid2, Typography } from '@mui/material';
import AudioPlayer from 'react-h5-audio-player'
import { AudioRecorder } from 'react-audio-voice-recorder';
import { useAudioRecorder } from 'react-audio-voice-recorder';
import 'react-h5-audio-player/lib/styles.css';
import { useAuth0 } from '@auth0/auth0-react';
import CircularProgress from '@mui/material/CircularProgress';
import { sendAudioAndGetStream } from '../services/api';

const ConversationalChat = () => {
  const url = process.env.REACT_APP_API_URL; // Replace with your Flask API URL
  const [isTextMode, setIsTextMode] = useState(false); // Toggle state for text/mic mode
  const [isRecording, setIsRecording] = useState(false); // Recording state
  const [textInput, setTextInput] = useState(""); // Text input state
  const recorderControls = useAudioRecorder();

  const {user} = useAuth0()

   const [src, setSrc] = useState("");
   const [isThingking , setIsThingking] = useState("none");

   const onRecordingEnd = (audio) => {
     
      var formData = new FormData();
      formData.append ("file" , audio )
      formData.append ("email" , user.email)

      setIsThingking ("inline")
      sendAudioAndGetStream(formData).then((response) => {

          const reader = response.body.getReader();
          return new ReadableStream({
            start(controller) {
              return pump();
              function pump() {
                return reader.read().then(({ done, value }) => {
                  // When no more data needs to be consumed, close the stream
                  if (done) {
                    controller.close();
                    return;
                  }
                  // Enqueue the next data chunk into our target stream
                  controller.enqueue(value);
                  return pump();
                });
              }
            },
          });
        })
        // Create a new response out of the stream
        .then((stream) => new Response(stream))
        // Create an object URL for the response
        .then((response) => response.blob())
        .then((blob) =>  URL.createObjectURL(blob))
        .then((url) =>  {
            setSrc ( url );
            setIsThingking("none");
         })
         .catch((err) => console.error(err));
   }
  
   const addAudioElement = (blob) => {
      onRecordingEnd ( blob )
  };

  const borderLayout = 0;

  return <>
     <Grid2 container border={borderLayout} justifyContent={"center"} >
        <Grid2 item border={borderLayout} justifyContent={"center"} >
            <Box display={"flex"}  padding={2} justifyContent={"center"}>
              <AudioRecorder onRecordingComplete={(blob) => addAudioElement(blob)}
                             recorderControls={recorderControls}
                             downloadOnSavePress={false}
                             downloadFileExtension="mp4"/>
            </Box>
            <Box flexDirection={"column"} padding={2} display={isThingking}>
              <Box display={"flex"} justifyContent={"center"}>
                <CircularProgress size={60} color='secondary'/>
              </Box>
              <Box display={"flex"} justifyContent={"center"}>
                Pensando...
              </Box>
            </Box>
            <Box display={"flex"} justifyContent={"center"} padding={2} >
              <Typography>Por favor presiona el microfono para hablar.</Typography>
            </Box>
            <Box display={"flex"} justifyContent={"center"} padding={2} >
              <Typography>Considera esto como una sesión</Typography>
            </Box>
            <Box>
                <AudioPlayer autoPlay src={src}  />
             </Box>
        </Grid2>
        
        </Grid2>
  </>
   
};

export default ConversationalChat;
