import React from 'react';
import Box from '@mui/material/Box';
import { Grid2 } from '@mui/material';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

const LoadingScreen = () => {
  return (
      <Grid2 item display={"flex"} justifyContent={"center"} size={{xs : 12}}>
       <Box
         sx={{
           display: 'flex',
           flexDirection: 'column',
           justifyContent: 'center',
           alignItems: 'center',
           height: '100vh'
         }}
       >
         <CircularProgress size={60} color='secondary' />
         <Typography variant="h6" sx={{ marginTop: 4}}>
             Cargando, por favor espera...
         </Typography>
       </Box>
      </Grid2>
  );
};

export default LoadingScreen;
