import React, { useEffect, useState } from "react";
import { Button, FormControl, Typography } from "@mui/material";
import { border, styled } from "@mui/system";
import { saveOrUpdateUser , getUser , getComunas } from "../services/api";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import dayjs from 'dayjs';
import LoadingScreen from "../Loading";
import InputAdornment from '@mui/material/InputAdornment';
import Grid2 from "@mui/material/Grid2";
import TextField from "@mui/material/TextField";

const FormGrid = styled(Grid2)(() => ({
  display: "flex",
  flexDirection: "column",
}));

export default function UserData(props) {
   
  const userEmail = props.user.email;
  const resumeUserLoginUrl = props.resumeUrl 
  const [isLoading , setIsLoading] = useState (false);
  const [comunas , setComunas] = useState ([])

  const [formData, setFormData] = useState({
    email: {
      value : userEmail,
      error : false
    },
    nombres:  {
      value : "",
      error : false
    },
    apellidos: {
      value : "",
      error : false
    },
    direccion : {
      value : "" ,
      error : false
    },
    celular: {
      value : "",
      error : false
   },
   fecha_nacimiento: {
      value : "",
      error : false
   },
   genero:  {
     value : "",
     error : false
   },
    comuna: {
        value : "",
        error : false
    }
  });
  
  const handleChange = (e) => {
    const {name, value} = e.target;
    setFormData({
      ...formData,
      [name]:{
        ...formData[name],
        value
      }
    })
  };

  function handleChangeDate (e ,ctx, name) {
    if (e !== null) {
      const value = dayjs(e.$d).format("DD/MM/YYYY")
      setFormData({
        ...formData,
        [name]:{
          ...formData[name],
          value
        }
      })
    }
  };

  useEffect ( () => {
    if (!props.isFirstLogin) {
      setIsLoading(true)
      getUser (props.user.email).then ( (response) =>  {
        setFormData (response.data) 
        setIsLoading (false);
      });
    }

    if (comunas.length === 0)
      getComunas ().then ( response => {
          setComunas (response.data)
      })
      
  }, []);
  
 
  const handleSubmit = async ( e ) => {
  
    e.preventDefault();

    const formFields = Object.keys(formData);
    let newFormValues = {...formData}
    
    formFields.forEach ( currentField => {
      newFormValues = {
        ...newFormValues, 
        [currentField] : {
          ...newFormValues[ currentField ],
          error : (formData[currentField].value === '' || formData[currentField].value === null)
        }
      }
    });

    setFormData(newFormValues)

    const newKeys = Object.keys ( newFormValues );
    const errors = newKeys.filter ( e => newFormValues[e].error ).length > 0;
     
    if ( !errors )  {
      const onlyValues = newKeys.map ( key =>  {
        return  JSON.stringify( {
          [key] : newFormValues [key].value  
        })
      } );
      
      setIsLoading(true)
      const parsedPayLoadAsJson =  "{"+onlyValues.join().replaceAll (new RegExp("{|}", "g"),'')+"}";
      const response = await  saveOrUpdateUser ( JSON.parse ( parsedPayLoadAsJson ) ) 
      setIsLoading(false)
      
      if (resumeUserLoginUrl) {
        window.location.href = resumeUserLoginUrl;
      }
      
    }
  };
  
  if (isLoading)
    return (<>
      <Grid2 item size={{xs : 12}}>
        <LoadingScreen/>
      </Grid2>
    </>)

  return (
    <>
    <form noValidate onSubmit={handleSubmit}>
        <Grid2 item  size={{  xs: 12  }} p={0.5}> 
          <TextField fullWidth size="small" required 
                     value={formData.nombres.value}
                     error={formData.nombres.error}
                     label="Nombre" 
                     name="nombres"
                     slotProps={{htmlInput: {maxLength : 60}}}
                     onChange={handleChange} 
                     variant="outlined" />
        </Grid2>
        <Grid2 item size={{  xs: 12 }} p={0.5}>
          <TextField fullWidth size="small" required 
                     value={formData.apellidos.value}
                     error={formData.apellidos.error}
                     slotProps={{htmlInput: {maxLength : 60}}}
                     label="Apellidos" 
                     name="apellidos"                     
                     onChange={handleChange}
                     variant="outlined" />
        </Grid2>
        <Grid2 item size={{  xs: 12 }} p={0.5}>
          <TextField fullWidth  size="small" required 
                     value={formData.direccion.value}
                     error={formData.direccion.error}
                     slotProps={{
                            htmlInput: {maxLength : 100},
                          }}
                     label="Dirección" 
                     name="direccion"
                     onChange={handleChange}
                     variant="outlined" />
        </Grid2>
        <Grid2 item size={{  xs: 12 }} p={0.5}>
          <Grid2 container border={0} display={"flex"} justifyContent={"space-between"} >
            <Grid2 item size={{xs: 6}} border={0}  >
                  <TextField fullWidth  size="small" name="celular" required
                             value={formData.celular.value}
                             error={formData.celular.error}
                             slotProps={{
                                htmlInput : {
                                  maxLength : 9
                                },
                                input: {
                                  startAdornment: <InputAdornment position="start">+56</InputAdornment>,
                                }
                             }}
                             onChange={handleChange}
                             label="Celular" 
                             variant="outlined" />
              </Grid2>
              <Grid2 item size={{xs: 5}} border={0} >
              <FormControl fullWidth size="small" >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker slotProps={{ 
                                    textField: 
                                        { 
                                          size: 'small' , 
                                          fullWidth: true, 
                                          required : true , 
                                          error : formData.fecha_nacimiento.error,
                                          value : dayjs(formData.fecha_nacimiento.value)
                                        }}}  
                                        onChange={ (e,x) => handleChangeDate ( e,x,"fecha_nacimiento" )}
                                        label={"Fecha de Nacimiento"} 
                                        name="fecha_nacimiento" 
                                        value={dayjs(formData.fecha_nacimiento.value)}
                                        />
                  </LocalizationProvider>
                  </FormControl>
              </Grid2>
          </Grid2>
        </Grid2>
        <Grid2 item size={{ xs : 12 }} p={0.5}>
            <TextField fullWidth size="small"
                       value={formData.email.value}
                       error={formData.email.error}
                       label="Email" 
                       onChange={handleChange}
                       variant="outlined" disabled />
        </Grid2> 
       <Grid2 item size={{xs : 12}} p={0.5}>
          <FormControl fullWidth size="small" required error={formData.genero.error} > 
            <InputLabel id="demo-simple-select-label">Genero</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formData.genero.value}
                label="Genero"
                name="genero"
                onChange={handleChange}>
                <MenuItem value={10}>Masculino</MenuItem>
                <MenuItem value={20}>Femenino</MenuItem>
                <MenuItem value={30}>Otro</MenuItem>
              </Select>
            </FormControl>
        </Grid2>
        <Grid2 item size={{xs : 12}} border={0} p={0.5}>
          <FormControl fullWidth size="small" required error={formData.comuna.error}>
            <InputLabel id="demo-simple-select-label">Comuna</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formData.comuna.value}
                label="Comuna"
                name="comuna"
                onChange={handleChange}>{comunas.map ( e => <MenuItem value={e.comuna_id}>{e.descripcion_comuna}</MenuItem>)}
              </Select>
            </FormControl>
        </Grid2>
        <Grid2 item size={{  xs: 12 }} display={"flex"} justifyContent={"center"} border={0} p={0.5}>
          <Button type="submit"
          fullWidth
            variant="contained"
            color="secondary"
            sx={{ textTransform : "none"}}
            size="large">
                {
                  !props.isFirstLogin ? "Actualizar" : "Crear Cuenta"
                }
          </Button>
        </Grid2>
      </form>
      </>
  );
}
