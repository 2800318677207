import axios from "axios";

const appUrl = process.env.REACT_APP_API_URL;
const api = axios.create({
  baseURL: appUrl,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
});

const sendEmail = async ({ email, nombre, tipo, mensaje, captchaToken }) => {
  try {
    console.log(captchaToken);

    const response = await api.post("/api/email/send", {
      user_email: email,
      name: nombre,
      role: tipo,
      message: mensaje,
      captchaToken,
    });

    return response;
  } catch (error) {
    console.error("Failed to send email:", error.response || error.message);
    throw error;
  }
};


const saveOrUpdateUser = async ({ ...data }) => {
  try {
    const response = await api.post("/api/user/", {
      ...data,
    });

    return response;
  } catch (error) {
    console.error("Failed to update user:", error.response || error.message);
    throw error;
  }
};

const getUser = (email) => { 

    try {

      return api.get(`/api/user/?email=${encodeURIComponent(email)}`);

    } catch (error) {
        console.error ("Error getting the user . ", error)
        throw error
    } 
}

const getComunas = () => { 

  try {

    return api.get(`/api/params/comunas`);

  } catch (error) {
      console.error ("Error getting the user . ", error)
      throw error
  } 
}

const sendAudioAndGetStream = (formData) => {
  return fetch(`${appUrl}/api/chat-bot/query`, {
    method : "POST",
    xhrFields: {
      responseType: "arraybuffer"
    },
    body : formData
  })
}

export { sendEmail, saveOrUpdateUser , getUser , getComunas , sendAudioAndGetStream};
