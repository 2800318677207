import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { CssBaseline } from '@mui/material';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@emotion/react';


const colors = {
  green       : "#099",
  clearGreen  : "#05CC91",
  salmon      : "#B1624EFF",
  skyBlue     : "#89ABE3FF",
  white       : "#FFF" ,
  duskyCitron : "#E3CD81FF",
  orange      : "#F2AA4CFF",
  beige       : "#D4B996FF",
  turkishSea  : "#195190FF",
  silver      : "#A2A2A1FF",
  purple      : "#c60f6b",
  black       : "#000000"
} 

const mainColor = colors.clearGreen;
const textColor = colors.white;
const tertiary = colors.turkishSea;
const quaternary = colors.beige;

const customTheme = createTheme({
  palette: {
    background : {
      default : "#8CE1DE"
    },
    primary: {
      main: "#8CE1DE"
    },
    secondary : {
      main : "#4da9b7"
    }
  },
  typography: {
    allVariants: {
      fontFamily: "'Montserrat', sans-serif",
      textTransform: "none",
    },
    button: {
      textTransform: "none",
    }
   }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={customTheme}>
    <CssBaseline />
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
