import React from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import MyContainer from "../MyContainer";


const Footer = () => {
  return (
    <MyContainer>
    <footer>
      <div>
        <h2 className="footer-title">Contacto</h2>
        <p>SÍGUENOS</p>
        <div className="social-links">
          <a
            href="https://www.instagram.com/whitemirror_app"
            aria-label="Instagram"
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstagramIcon sx={{ fontSize: 35 }} color="secondary"/>
          </a>
          <a
            href="https://www.linkedin.com/in/diego-pavez/"
            aria-label="LinkedIn"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedInIcon sx={{ fontSize: 35 }} color="secondary"/>
          </a>
        </div>
        <p>O envíanos un correo a:</p>
        <a
          href="mailto:mejorsaludmental@gmail.com"
          className="footer-email"
        >
          mejorsaludmental@gmail.com
        </a>
      </div>
    </footer>
  </MyContainer>
  );
};

export default Footer;

