import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Auth0Provider } from '@auth0/auth0-react';
import Landing from './components/landing/Landing';
import SideBar from './components/pantalla_inicial/SideBar.jsx';
import ChatBar from './components/pantalla_inicial/ChatBar.jsx';
import ConversationalChat from './components/conversational-chat/ConversationalChat.jsx';
import Profile from './components/pantalla_inicial/Profile';
import Feedback from './components/pantalla_inicial/Feedback';
import Premium from './components/pantalla_inicial/Premium';
import CompleteInformation from './components/auth/CompleteInformation.jsx';
import ChatbotText from './components/pantalla_inicial/chatbottext.jsx';
import Header from './components/Header.jsx';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { orange } from '@mui/material/colors';

function App() {
  const captchaSiteKey = process.env.REACT_APP_CAPTCHA_SITE_KEY?.trim();
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{ redirect_uri: window.location.origin }}
      useRefreshTokens
      cacheLocation="localstorage">
      
      <GoogleReCaptchaProvider reCaptchaKey={captchaSiteKey}>
          <BrowserRouter>
            <Header/>
            <Routes>
              <Route path="/complete-information" element={<CompleteInformation/>} />
              <Route path="/" element={<Landing />} />
              <Route path="/*" element={<>
                    <Routes>
                      <Route path="/dashboard" element={<ConversationalChat />} />
                      <Route path="/profile" element={<Profile />} />
                      <Route path="/feedback" element={<Feedback />} />
                      <Route path="/premium" element={<Premium />} />
                      <Route path="/chatbottext" element={<ChatbotText />} />
                    </Routes>
                    </>
                }
              />
            </Routes>
          </BrowserRouter>
      </GoogleReCaptchaProvider>
    </Auth0Provider>
  );
}

export default App;
