import React from "react";
import { Box, Typography, Paper } from "@mui/material";

const ChatBar = ({ messages }) => {
  return (
    <Box
      sx={{
        width: "25%",
        height: "100vh",
        position: "fixed",
        top: 0,
        right: 0,
        backgroundColor: "#f4f4f4",
        overflowY: "auto",
        boxShadow: "-4px 0px 8px rgba(0, 0, 0, 0.1)",
        padding: 2,
      }}
    >
      <Typography variant="h6" sx={{ marginBottom: 2 }}>
        Chat History
      </Typography>
      {messages.map((message, index) => (
        <Paper
          key={index}
          elevation={2}
          sx={{
            marginBottom: 2,
            padding: 1.5,
            backgroundColor: message.type === "IA" ? "#e3f2fd" : "#ffebee",
            alignSelf: message.type === "IA" ? "flex-start" : "flex-end",
            textAlign: message.type === "IA" ? "left" : "right",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontWeight: "bold", // Bold for IA: and You:
            }}
          >
            {message.type === "IA" ? "IA" : "You"}:
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontWeight: "normal", // Normal for the actual message text
            }}
          >
            {message.text}
          </Typography>
        </Paper>
      ))}
    </Box>
  );
};

export default ChatBar;
