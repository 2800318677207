import React from "react";
import { Grid2, Typography } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import Login from "./auth/Login";
import TopMenu from "./auth/TopMenu";
import Box from "@mui/material/Box";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';


const bordeLayout = 1;

const Header = () => {
  const { isAuthenticated  } = useAuth0();

  return (
    <>
    <Grid2 container display={"flex"} justifyContent={"center"} >
       <Grid2 item size={{lg : 8 , sm: 12 , xs : 12}}>
          <AppBar position="static" elevation={0}>
              <Toolbar>
                <IconButton
                  size="small"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                >
                  <a href="/">
                    <img width={60} height={60} src="/icono_wm.png" />
                  </a>
                </IconButton>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  White Mirror
                </Typography>
                {
                  isAuthenticated ? 
                  <Box display={"flex"} alignItems={"center"}>
                      <TopMenu />
                  </Box>
                  :
                  <Login/>
                }
              </Toolbar>
          </AppBar>
       </Grid2>
    </Grid2>
    {/* <Grid2 container border={0} paddingTop={1} >
      <Grid2 item border={bordeLayout} display={"flex"} alignItems={"center"} >
        <Box display={"flex"}>
          <a href="/">
            <img width={60} height={60} src="/icono_wm.png" />
          </a>
        </Box>
      </Grid2>
      <Grid2
        item
        size={{ lg: 2 }}
        border={bordeLayout}
        display={"flex"}
        alignItems="center"
      >
        <Typography>
          White Mirror
        </Typography>
      </Grid2>
      <Grid2
        item
        flexGrow={1}
        border={bordeLayout}
        display={"flex"}
        justifyContent={"flex-end"}
        alignItems={"center"}
        >
        {
          isAuthenticated ? 
          <Box display={"flex"} alignItems={"center"}>
              <TopMenu />
          </Box>
          :
          <Login/>
        }
      </Grid2>
    </Grid2> */}
    </>
  );
};

export default Header;
