import React from 'react';
import { Typography, TextField, Button, Box } from '@mui/material';

const Premium = () => (
  <Box>
      <Typography variant="h4">Bienvenido al premium</Typography>
      <Typography>
        **placeholder de premium**
      </Typography>
    </Box>
);

export default Premium;
