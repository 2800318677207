import UserData from '../user_data/UserData';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from "react";
import { Grid2, Typography } from '@mui/material';


const CompleteInformation = () => {
    const [searchParams] = useSearchParams();
    const [resumeUrl , setResumeUrl] = useState("")
    
    useEffect ( () => {
        setResumeUrl( `https://${process.env.REACT_APP_AUTH0_DOMAIN}/continue?state=${searchParams.get("state")}` );
    })

    return <>
        <Grid2 container p={2}>
            <Grid2 item size={{xs : 12}} display={"flex"} justifyContent={"center"} padding={3}>
                <Typography>Necesitamos algunos datos para continuar ... </Typography>
            </Grid2>
            <UserData user={{email : searchParams.get("email")}}  
                  resumeUrl={resumeUrl} 
                  isFirstLogin />

        </Grid2>
    </>
}

export default CompleteInformation;